//these are posix timezone codes with the associated timezone number
let timezonesSpecific = [
    {text: '<-11>11', value: '-11'},
    {text: 'SST11', value: '-11'},
    {text: '<-10>10', value: '-10'},
    {text: 'HST10', value: '-10'},
    {text: '<-0930>9:30', value: '-9:30'},
    {text: '<-09>9', value: '-9'},
    {text: 'HST10HDT,M3.2.0,M11.1.0', value: '-9'},
    {text: '<-08>8', value: '-8'},
    {text: 'AKST9AKDT,M3.2.0,M11.1.0', value: '-8'},
    {text: '<-07>7', value: '-7'},
    {text: 'MST7', value: '-7'},
    {text: 'PST8PDT,M3.2.0,M11.1.0', value: '-7'},
    {text: '<-06>6', value: '-6'},
    {text: 'CST6', value: '-6'},
    {text: 'MST7MDT,M3.2.0,M11.1.0', value: '-6'},
    {text: 'MST7MDT,M4.1.0,M10.5.0', value: '-6'},
    {text: '<-05>5', value: '-5'},
    {text: 'EST5', value: '-5'},
    {text: 'CST6CDT,M3.2.0,M11.1.0', value: '-5'},
    {text: 'CST6CDT,M4.1.0,M10.5.0', value: '-5'},
    {text: '<-04>4', value: '-4'},
    {text: 'AST4', value: '-4'},
    {text: 'EST5EDT,M3.2.0,M11.1.0', value: '-4'},
    {text: '<-03>3', value: '-3'},
    {text: 'AST4ADT,M3.2.0,M11.1.0', value: '-3'},
    {text: '<-02:30>2:30', value: '-2:30'},
    {text: '2NST3:30NDT,M3.2.0,M11.1.0', value: '-2:30'},
    {text: '<-02>2', value: '-2'},
    {text: '<-03>3<-02>,M3.5.0/-2,M10.5.0/-1', value: '-2'},
    {text: '<-01>1', value: '-1'},
    {text: 'GMT0', value: '0'},
    {text: 'GMT0BST,M3.5.0/1,M10.5.0', value: '0'},
    {text: 'UTC0', value: '0'},
    {text: '<-01>1<+00>,M3.5.0/0,M10.5.0/1', value: '0'},
    {text: '<+01>-1', value: '1'},
    {text: 'CET-1', value: '1'},
    {text: 'WAT-1', value: '1'},
    {text: 'IST-1GMT0,M10.5.0,M3.5.0/1', value: '1'},
    {text: '<+02>-2', value: '2'},
    {text: 'EET-2', value: '2'},
    {text: 'CAT-2', value: '2'},
    {text: 'CET-1CEST,M3.5.0,M10.5.0/3', value: '2'},
    {text: '<+03>-3', value: '3'},
    {text: 'EAT-3', value: '3'},
    {text: 'EET-2EEST,M3.5.0/3,M10.5.0/4', value: '3'},
    {text: '<+0330>-3:30', value: '3:30'},
    {text: '<+0330>-3:30<+0430>,J79/24,J263/24', value: '3:30'},
    {text: '<+04>-4', value: '4'},
    {text: '<+0430>-4:30', value: '4:30'},
    {text: '<+05>-5', value: '5'},
    {text: '<+0530>-5:30', value: '5:30'},
    {text: '<+0545>-5:45', value: '5:45'},
    {text: '<+06>-6', value: '6'},
    {text: '<+0630>-6:30', value: '6:30'},
    {text: '<+07>-7', value: '7'},
    {text: '<+08>-8', value: '8'},
    {text: 'CST-8', value: '8'},
    {text: '<+0845>-8:45', value: '8:45'},
    {text: '<+09>-9', value: '9'},
    {text: '<+0930>-9:30', value: '9:30'},
    {text: 'ACST-9:30', value: '9:30'},
    {text: '<+10>-10', value: '10'},
    {text: '<+1030>-10:30', value: '10:30'},
    {text: 'ACST-9:30ACDT,M10.1.0,M4.1.0/3', value: '10:30'},
    {text: '<+11>-11', value: '11'},
    {text: '<+12>-12', value: '12'},
    {text: '<+13>-13', value: '13'},
    {text: '<+1345>-13:45', value: '13:45'},
    {text: '<+1245>-12:45<+1345>,M9.5.0/2:45,M4.1.0/3:45', value: '13:45'},
    {text: '<+14>-14', value: '14'}
]

let timezonesGeneric = [
    {text: 'GMT-11', value: '-11'},
    {text: 'GMT-10', value: '-10'},
    {text: 'GMT-9:30', value: '-9:30'},
    {text: 'GMT-9', value: '-9'},
    {text: 'GMT-8', value: '-8'},
    {text: 'GMT-7', value: '-7'},
    {text: 'GMT-6', value: '-6'},
    {text: 'GMT-5', value: '-5'},
    {text: 'GMT-4', value: '-4'},
    {text: 'GMT-3', value: '-3'},
    {text: 'GMT-2:30', value: '-2:30'},
    {text: 'GMT-2', value: '-2'},
    {text: 'GMT-1', value: '-1'},
    {text: 'GMT+0', value: '0'},
    {text: 'GMT+1', value: '1'},
    {text: 'GMT+2', value: '2'},
    {text: 'GMT+3', value: '3'},
    {text: 'GMT+3:30', value: '3:30'},
    {text: 'GMT+4', value: '4'},
    {text: 'GMT+4:30', value: '4:30'},
    {text: 'GMT+5', value: '5'},
    {text: 'GMT+5:30', value: '5:30'},
    {text: 'GMT+5:45', value: '5:45'},
    {text: 'GMT+6', value: '6'},
    {text: 'GMT+6:30', value: '6:30'},
    {text: 'GMT+7', value: '7'},
    {text: 'GMT+8', value: '8'},
    {text: 'GMT+8:45', value: '8:45'},
    {text: 'GMT+9', value: '9'},
    {text: 'GMT+9:30', value: '9:30'},
    {text: 'GMT+10', value: '10'},
    {text: 'GMT+10:30', value: '10:30'},
    {text: 'GMT+11', value: '11'},
    {text: 'GMT+12', value: '12'},
    {text: 'GMT+13', value: '13'},
    {text: 'GMT+13:45', value: '13:45'},
    {text: 'GMT+14', value: '14'},
]

function findTimeZone(timezone) {
    let index = timezonesSpecific.findIndex(time => {
        return timezone === time.text
    })
    if (index !== -1) {
        return timezonesSpecific[index]
    }
    else {
        return {value: null};
    }
}

function findReadableTimeZone(timezone) {
    let temp = findTimeZone(timezone).value;
    let index = timezonesGeneric.findIndex(time => {
        return temp === time.value
    })
    if (index !== -1) {
        return timezonesGeneric[index].text
    }
    else {
        return null;
    }
}

export {
    findTimeZone, findReadableTimeZone, timezonesGeneric
}