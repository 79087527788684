<template>
    <b-modal hide-header hide-footer no-close-on-backdrop no-body no-close-on-esc title="Camera Preview" class="modal-dark" size="lg"
             v-model="value" v-if="liveStream.device">
        <div style="margin: -16px; height: 447px" class="rounded">

            <div v-if="!liveStream.device.error" :id="'video-container-' + liveStream.device.getDeviceId() + liveStream.sourceToken + liveStream.dataChannel"
                 :key="liveStream.device.getDeviceId() + liveStream.sourceToken" style="position: relative; height: 100%; width: 100%"
                 class="flex-fill d-flex noHighlight">
                <stream :device="liveStream.device" :sourceToken="liveStream.sourceToken" :quality="liveStream.quality" class="d-flex flex-fill" :id="liveStream.device.getDeviceId() + liveStream.sourceToken + liveStream.dataChannel"/>
                <PlayerControls :device="liveStream.device" :isStreamOptions="false" class="flex-fill h-100 w-100" :sourceToken="liveStream.sourceToken" :dataChannel="liveStream.dataChannel" :quality="liveStream.quality"
                                    :playerId="liveStream.device.getDeviceId() + liveStream.sourceToken + liveStream.dataChannel" containerIDPrefix="video-container-" style="position: absolute" :video-wall="false" @close="hideVideoModal"/>
            </div>

            <div v-if="liveStream.sensors.length > 1" class="w-100 d-flex justify-content-center" style="margin: 10px 0">
                <b-form-select size="sm" v-model="liveStream.sourceToken" :options="liveStream.sensors"
                               style="width: fit-content"/>
            </div>

        </div>
    </b-modal>
</template>

<script>
import PlayerControls from "@/views/video_wall/PlayerControls";
import stream from "@/views/video_wall/stream";

export default {
    name: "CameraFeedModal",
    components: {
        PlayerControls,
        stream
    },
    props: {
        value: Boolean,
        liveStream: Object
    },
    created() {
        document.addEventListener("keydown", ({key}) => {
            if (key === "Escape") {
                this.hideVideoModal()
            }
        })
    },
    methods: {
        hideVideoModal() {
            this.$emit('hideVideoModal')
        }
    }
}
</script>

<style scoped>

</style>