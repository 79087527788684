<template>
    <div>

        <!-- BUTTON -->
        <b-button size="sm" variant="dark" @click="openScanIdentifyModal">
            <fa-icon :icon="['fas', 'magnifying-glass-plus']"/>
        </b-button>

        <!-- SCAN/IDENTIFY MODAL -->
        <b-modal no-close-on-backdrop scrollable title="Scan or Identify" class="modal-dark" v-model="scanIdentifyModal">
            <p>
                Constellation:
                <b-form-select v-model="selectedConstellation" :options="constellations"/>
            </p>
            <template slot="modal-footer">
                <b-button :disabled="!selectedConstellation" size="sm" variant="primary"
                          @click="identify">Identify</b-button>
                <b-button :disabled="!selectedConstellation" size="sm" variant="primary"
                          @click="openDeviceScannerModal">Scan</b-button>
            </template>
        </b-modal>

        <!-- DEVICE SCANNER MODAL -->
        <b-modal no-close-on-backdrop scrollable title="Device Scanner" class="modal-dark" v-model="deviceScannerModal">
            <p>Target Cidr:
                <b-form-input @keyup.enter="scanEnterButton" v-model="targetCidr" type="text"
                              placeholder="192.168.100.14/24"></b-form-input>
            </p>
            <p>Target Port:
                <b-form-input @keyup.enter="scanEnterButton" v-model="targetPort" type="text"
                              placeholder="9999"></b-form-input>
            </p>
            <template slot="modal-footer">
                <b-button :disabled="!targetCidr || !targetPort" size="sm" variant="primary"
                          @click="scan(false)">Scan
                </b-button>
                <b-button :disabled="!targetCidr || !targetPort" size="sm" variant="primary"
                          @click="scan(true)">Scan & Identify
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
import constellation from "@/services/constellation";
import constellationStore from "@/store/constellationStore";

export default {
    name: 'ScanDevices',
    props: ['curConstellation', 'constellations'],
    data: () => {
        return {
            scanIdentifyModal: false,
            deviceScannerModal: false,
            selectedConstellation: null,
            targetCidr: '',
            targetPort: '',
        }
    },
    methods: {
        openScanIdentifyModal() {
            this.selectedConstellation = this.curConstellation;
            this.scanIdentifyModal = true;
            this.scan = {};
        },
        openDeviceScannerModal() {
            this.scanIdentifyModal = false;
            this.deviceScannerModal = true;
            this.targetCidr = '';
            this.targetPort = '';
        },
        async scan(identify) {
            let scanArr = this.selectedConstellation._id ? [this.selectedConstellation] : this.constellations.map(_ => _.value)
            for (let i = 0; i < scanArr.length; i++) {
                let constellation = scanArr[i]
                if (constellation._id) {
                    let retrievedConst = await constellationStore.dispatch('getConstellation', this.constellationScan);
                    constellation.createObjectScanScan(retrievedConst.url + ":" + retrievedConst.port, this.scan).then(() => {
                        this.$mToast({
                            title: 'Success',
                            text: 'Devices scanned',
                            style: 'success'
                        });
                        this.getAllDevices()
                    }).catch(() => {
                        this.$mToast({
                            title: 'Error',
                            text: "Devices couldn't be scanned",
                            style: 'error'
                        });
                    });
                }
            }
            if (identify) {
                this.identify()
            }
            this.scanIdentifyModal = false;
        },
        identify() {
            let identifyArr = this.selectedConstellation._id ? [this.selectedConstellation] : this.constellations.map(_ => _.value)
            identifyArr.forEach(constellation => {
                if (constellation._id) {
                    constellation.createObjectScanVoyager(constellation._id).then(() => {
                        this.$mToast({
                            title: 'Success',
                            text: 'Devices identified for ' + constellation.name,
                            style: 'success'
                        });
                    }).catch(() => {
                        this.$mToast({
                            title: 'Error',
                            text: "Device couldn't be updated for " + constellation.name,
                            style: 'error'
                        });
                    });
                }
            })
            this.scanIdentifyModal = false;
        },
    }
}
</script>

<style scoped>

</style>